<template>
  <div>
    <div class="text-right mb-3">
      <b-button
        class="text-success border border-success"
        @click="showInsertModal()"
        test-id="cm001-add-button"
        >Add
      </b-button>
    </div>
    <div class="text-center mt-3" v-if="isLoading">
      <b-spinner />
    </div>
    <div v-else>
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        placeholder="Type to Search"
        class="mb-3"
        test-id="cm001-search-input"
      ></b-form-input>
      <b-table
        striped
        hover
        :items="customerDataWithChargerSerialNumber"
        :fields="fields"
        :filter="filter"
        test-id="cm001-data-table"
      >
        <template #cell(customerExternalId)="data"
          ><span :class="{ 'text-italic': data.item.customerType === 'OTP' }">{{
            data.item.customerType === "OTP"
              ? "Unregistered customer"
              : data.item.customerExternalId
          }}</span>
        </template>
        <template #cell(serialNumber)="data"
          >{{ data.item.serialNumber }}
        </template>
        <template #cell(fullName)="data">{{ data.item.fullName }}</template>
        <template #cell(email)="data">{{ data.item.email }}</template>
        <template #cell(otp)="data"
          ><span v-show="data.item.customerType === 'OTP'">{{
            data.item.otp
          }}</span></template
        >
        <template #cell(role)="data">{{ data.item.role }}</template>
        <template #cell(actions)="data">
          <div>
            <b-td
              class="border-0 pt-0"
              @click="showUpdateModal(data.item.id)"
              :test-id="'cm001-edit-button' + data.index"
            >
              <font-awesome-icon icon="edit" />
            </b-td>
            <b-td
              v-if="data.item.hasSso === false"
              class="border-0 pt-0"
              @click="showMailResendConfirm(data.item.id)"
              :test-id="'cm001-resend-button' + data.index"
            >
              <font-awesome-icon icon="envelope" />
            </b-td>
          </div>
        </template>
      </b-table>
    </div>

    <b-modal
      id="customer-charger-modal"
      ref="customer-charger-modal"
      v-model="isModalVisible"
      hide-footer
      :title="modalTitle"
    >
      <template>
        <b-form @submit.stop.prevent="submitCustomerCharger()">
          <b-form-group
            id="charger"
            label="Charger serial number (pick charger to link with customer)"
            label-for="chargerSelect"
          >
            <multiselect
              v-model="customerChargerForm.externalId"
              id="chargerSelect"
              placeholder="Search..."
              :options="chargers.map((c) => c.externalId)"
              :customLabel="
                (externalId) => this.chargerSerialNumberMap[externalId]
              "
              :allow-empty="false"
              deselect-label=""
            />
            <span class="text-error" v-show="this.errors.externalId"
              >Select a charger</span
            >
          </b-form-group>
          <b-form-group
            id="customer"
            label="Customer code"
            label-for="customerInput"
          >
            <b-form-input
              placeholder="Enter customer code to link existing customer"
              id="customerInput"
              @click="clearErrorMessage()"
              v-model="customerChargerForm.customerExternalId"
              :disabled="isUpdateModal"
            />
            <span class="text-error" v-show="this.errors.customerExternalId"
              >Enter customer code</span
            >
            <span class="text-error" v-show="this.errorMessage != null">{{
              errorMessage
            }}</span>
          </b-form-group>
          <b-form-group
            id="customerFullName"
            label="Customer Full Name"
            label-for="customerNameInput"
          >
            <b-form-input
              id="customerNameInput"
              v-model="customerChargerForm.fullName"
            />
            <span class="text-error" v-show="this.errors.fullName"
              >Enter full name</span
            >
          </b-form-group>
          <b-form-group
            id="customerEmail"
            label="Customer E-mail"
            label-for="customerEmailInput"
          >
            <b-form-input
              id="customerEmailInput"
              v-model="customerChargerForm.email"
            />
            <span class="text-error" v-show="this.errors.email"
              >Enter email</span
            >
          </b-form-group>
          <b-form-checkbox
            id="isOwner"
            v-model="customerChargerForm.role"
            name="isOwner"
            value="OWNER"
            unchecked-value="USER"
            class="mb-3"
            :disabled="selectedCustomer && selectedCustomer.role === 'OWNER'"
            >Owner
          </b-form-checkbox>
          <b-button
            size="sm"
            variant="danger"
            @click.prevent="showDeleteConfirmAlert"
            v-if="modalOperation !== 'insert'"
            >Delete
          </b-button>
          <b-button
            size="sm"
            variant="success"
            type="submit"
            class="float-right"
            test-id="cm001-modal-submit"
            >Submit
          </b-button>
        </b-form>
      </template>
    </b-modal>
    <div class="text-center">
      <b-alert :show="confirmDeleteAlert" class="fixed-top" variant="light">
        <span class="text-dark">
          {{ deleteMessage }}
        </span>
        <div class="mt-3">
          <b-button
            v-if="
              this.selectedCustomer && this.selectedCustomer.role === 'OWNER'
            "
            class="text-success ml-3 border border-success confirmBtn"
            @click="deleteCustomerCharger(true)"
          >
            <font-awesome-icon icon="check" class="mr-1" />
            Delete owner, all users and generate OTP
          </b-button>
          <b-button
            class="text-success ml-3 border border-success confirmBtn"
            @click="deleteCustomerCharger()"
          >
            <font-awesome-icon icon="check" class="mr-1" />
            {{
              this.selectedCustomer && this.selectedCustomer.role === "OWNER"
                ? "Delete owner and all users"
                : "Delete"
            }}
          </b-button>
          <b-button
            class="btn--primary confirmBtn ml-3"
            @click="hideDeleteConfirmAlert"
          >
            <svg class="icon mr-1">
              <use xlink:href="@/assets/svg/icons.svg#x" />
            </svg>
            Cancel
          </b-button>
        </div>
      </b-alert>
    </div>
    <div class="text-center">
      <b-alert :show="confirmMailResendAlert" class="fixed-top" variant="light">
        <span class="text-dark">
          New activation mail will be sent and all other activation links will
          be deleted. Continue?
          <b-button
            class="text-success mr-1 ml-4 border border-success confirmBtn"
            @click="resendActivationMail()"
          >
            <font-awesome-icon icon="check" class="mr-1" />
            Yes
          </b-button>
          <b-button
            class="btn--primary confirmBtn"
            @click="hideMailResendConfirm"
          >
            <svg class="icon mr-1">
              <use xlink:href="@/assets/svg/icons.svg#x" />
            </svg>
            No
          </b-button>
        </span>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BTd,
  BSpinner,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import {
  createCustomerCharger,
  deleteCustomerCharger,
  getCustomerChargers,
  resendActivationMail,
  updateCustomerCharger,
} from "@/api/CustomerManagementApi";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faTrash,
  faCheck,
  faEdit,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getChargers } from "@/api/ChargersListApi";
import Multiselect from "vue-multiselect";
import { generateChargerSerialNumberMap } from "@/mixins/charger_utils";

library.add(faLink, faTrash, faCheck, faEdit, faEnvelope);
export default {
  components: {
    "b-td": BTd,
    "b-spinner": BSpinner,
    "b-button": BButton,
    "b-modal": BModal,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form": BForm,
    "font-awesome-icon": FontAwesomeIcon,
    multiselect: Multiselect,
  },
  name: "customerChargerTable",
  data() {
    return {
      filter: null,
      chargers: [],
      chargerSerialNumberMap: {},
      customerData: [],
      deleteMessage: "",
      errorMessage: null,
      selectedCustomer: null,
      modalOperation: null,
      isConfirmLoading: false,
      confirmDeleteAlert: false,
      confirmMailResendAlert: false,
      fields: [
        {
          key: "serialNumber",
          label: "Charger",
          sortable: true,
        },
        {
          key: "customerExternalId",
          label: "Customer code",
          sortable: true,
        },
        {
          key: "fullName",
          label: "Full Name",
          sortable: true,
        },
        {
          key: "email",
          label: "E-Mail",
          sortable: false,
        },
        {
          key: "otp",
          label: "OTP",
          sortable: false,
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      isLoading: true,
      isModalVisible: false,
      customerChargerForm: this.initialChargerForm(),
      emailMatchingRegEx: /^\S+@\S+\.\S+$/,
      errors: {},
    };
  },
  computed: {
    modalTitle() {
      return this.modalOperation === "insert"
        ? "Add new customer charger link"
        : "Update customer charger link";
    },
    isUpdateModal() {
      return this.modalOperation === "update";
    },
    customerDataWithChargerSerialNumber() {
      return this.customerData.map((cd) => {
        return {
          serialNumber: this.chargerSerialNumberMap[cd.externalId],
          ...cd,
        };
      });
    },
  },
  methods: {
    saveNewCustomer(customer) {
      this.customerData.push(customer);
    },
    // UI
    showSuccessMessage() {
      this.$emit("send-message", true);
    },
    showErrorMessage() {
      this.$emit("send-message", false);
    },
    // Backend
    fetchCustomerChargers() {
      this.isLoading = true;
      getCustomerChargers()
        .then((response) => {
          this.customerData = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchChargers() {
      this.isLoading = true;
      getChargers()
        .then((response) => {
          this.chargers = response.data;
          this.chargerSerialNumberMap = generateChargerSerialNumberMap(
            this.chargers
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    saveCustomerCharger(customer) {
      this.isLoading = true;
      createCustomerCharger(customer)
        .then((response) => {
          this.fetchCustomerChargers();
          this.showSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.data &&
            error.response.data.detail.includes("Invalid customer code")
          ) {
            this.errorMessage = error.response.data.detail;
            return;
          }
          this.showErrorMessage();
        })
        .finally(() => {
          this.isLoading = false;
          if (this.errorMessage == null) {
            this.hideModal();
          }
        });
    },
    updateCustomerCharger(customer) {
      this.isLoading = true;
      updateCustomerCharger(customer)
        .then((response) => {
          this.fetchCustomerChargers();
          this.showSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
    deleteCustomerCharger(amendOwnerlessChargers = false) {
      this.hideDeleteConfirmAlert();
      this.isLoading = true;
      const id = this.customerChargerForm.id;
      deleteCustomerCharger(id, amendOwnerlessChargers)
        .then(() => {
          this.fetchCustomerChargers();
          this.showSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
    resendActivationMail() {
      this.hideMailResendConfirm();
      this.isLoading = true;
      const id = this.customerChargerForm.id;
      resendActivationMail(id)
        .then(() => {
          this.fetchCustomerChargers();
          this.showSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMessage();
        })
        .finally(() => {
          this.isLoading = false;
          this.hideModal();
        });
    },
    initialChargerForm() {
      return {
        id: null,
        externalId: null,
        customerExternalId: null,
        fullName: null,
        email: null,
        role: "USER",
      };
    },
    initialAddChargerForm() {
      this.clearErrorMessage();
      this.selectedCustomer = null;
      return this.initialChargerForm();
    },
    clearErrorMessage() {
      this.errorMessage = null;
    },
    showUpdateModal(id) {
      this.clearErrorMessage();
      this.modalOperation = "update";
      const customerCharger = this.customerData.find((item) => item.id === id);
      this.customerChargerForm = {
        id: customerCharger.id,
        customerExternalId:
          customerCharger.customerType === "OTP"
            ? null
            : customerCharger.customerExternalId,
        externalId: customerCharger.externalId,
        fullName: customerCharger.fullName,
        email: customerCharger.email,
        role: customerCharger.role,
        hasSso: customerCharger.hasSso,
      };
      this.selectedCustomer = customerCharger;
      this.showModal();
    },
    showInsertModal() {
      this.modalOperation = "insert";
      this.customerChargerForm = this.initialAddChargerForm();
      this.showModal();
    },
    showModal() {
      this.errors = {};
      this.isModalVisible = true;
    },
    hideModal() {
      this.clearErrorMessage();
      this.isModalVisible = false;
    },
    submitCustomerCharger() {
      if (!this.validateCustomerChargerForm(this)) {
        return;
      }

      if (this.customerChargerForm.id != null) {
        this.updateCustomerCharger(this.customerChargerForm);
      } else {
        this.saveCustomerCharger(this.customerChargerForm);
      }
    },
    showDeleteConfirmAlert() {
      const vm = this;
      vm.hideModal();
      vm.isConfirmLoading = true;
      vm.deleteMessage =
        this.selectedCustomer.role === "OWNER"
          ? "You are about to delete the current owner of the charger!"
          : "Are you sure you want to delete this charger-customer link?";
      setTimeout(() => {
        vm.isConfirmLoading = false;
        vm.confirmDeleteAlert = true;
      }, 500);
    },
    hideDeleteConfirmAlert() {
      const vm = this;
      vm.confirmDeleteAlert = false;
    },
    showMailResendConfirm(id) {
      const vm = this;
      this.customerChargerForm = {
        id: id,
      };
      vm.confirmMailResendAlert = true;
    },
    hideMailResendConfirm() {
      const vm = this;
      vm.confirmMailResendAlert = false;
    },
    validateCustomerChargerForm(self) {
      const errors = {};
      let valid = true;
      ["externalId", "fullName", "email"].forEach((property) => {
        if (!self.customerChargerForm[property]) {
          errors[property] = true;
          valid = false;
        }
      });
      self.errors = errors;
      return valid;
    },
    async fetchData() {
      await this.fetchChargers();
      this.fetchCustomerChargers();
    },
  },
  beforeMount() {
    const vm = this;
    vm.fetchData();
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #0fac5f;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #374454;
}
</style>
