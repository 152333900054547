<template>
  <div>
    <CustomerChargerTable @send-message="showMessage" />

    <div class="m-3 p-2 fontStyle w-50">
      <b-alert
        class="fixed-top"
        :show="messageCountDown"
        dismissible
        variant="success"
        @dismissed="messageCountDown = 0"
      >
        <p>Changes saved!</p>
      </b-alert>
      <b-alert
        class="fixed-top"
        :show="errorMessageCountDown"
        dismissible
        variant="danger"
        @dismissed="errorMessageCountDown = 0"
      >
        <p>Changes not saved!</p>
      </b-alert>
    </div>
  </div>
</template>

<script>
import CustomerChargerTable from "@/components/CustomerChargerTable";

export default {
  components: {
    CustomerChargerTable,
  },
  data() {
    return {
      constants: {
        MESSAGE_COUNTDOWN_SECONDS: 5,
      },
      messageCountDown: 0,
      errorMessageCountDown: 0,
    };
  },
  methods: {
    showSuccessMessage() {
      this.messageCountDown = this.constants.MESSAGE_COUNTDOWN_SECONDS;
    },
    showErrorMessage() {
      this.errorMessageCountDown = this.constants.MESSAGE_COUNTDOWN_SECONDS;
    },
    showMessage(wasSuccessful) {
      return wasSuccessful
        ? this.showSuccessMessage()
        : this.showErrorMessage();
    },
  },
};
</script>
